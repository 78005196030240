#contact {
    padding: 80px 40px;
    background-color: #f8f9fa;
    min-height: 80vh;
    display: flex;
    align-items: center;
}

.contact-container {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    gap: 60px;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.08);
    padding: 60px;
    position: relative;
    overflow: hidden;
}

.contact-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 6px;
    height: 100%;
    background: linear-gradient(180deg, #0c4a80 0%, #77AACA 100%);
}

.left-section {
    flex: 1;
    padding-right: 40px;
    position: relative;
}

.right-section {
    flex: 1.5;
    position: relative;
}

h1 {
    font-family: Avenir, sans-serif;
    font-size: 24px;
    color: #0c4a80;
    margin-bottom: 20px;
    position: relative;
    display: inline-block;
}

h1::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 40px;
    height: 3px;
    background-color: #0c4a80;
}

h2 {
    font-family: Avenir, sans-serif;
    font-size: 42px;
    font-weight: 900;
    color: #333f5b;
    margin-bottom: 30px;
    line-height: 1.2;
}

.left-section p {
    font-family: Avenir-Roman, sans-serif;
    font-size: 17px;
    line-height: 1.7;
    color: #505a72;
    margin-bottom: 40px;
}

.left-section a {
    color: #0c4a80;
    text-decoration: none;
    font-weight: 600;
    transition: color 0.3s ease;
    position: relative;
}

.left-section a:hover {
    color: #77AACA;
}

.left-section a::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: currentColor;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.3s ease;
}

.left-section a:hover::after {
    transform: scaleX(1);
    transform-origin: left;
}

.social-links {
    display: flex;
    gap: 20px;
    margin-top: 40px;
}

.social-links a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    background-color: #f8f9fa;
    border-radius: 50%;
    color: #0c4a80;
    font-size: 20px;
    transition: all 0.3s ease;
}

.social-links a:hover {
    background-color: #0c4a80;
    color: white;
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(12, 74, 128, 0.2);
}

.form-row {
    display: flex;
    gap: 20px;
    margin-bottom: 25px;
}

.form-group {
    flex: 1;
    margin-bottom: 25px;
}

label {
    display: block;
    margin-bottom: 10px;
    font-family: Avenir, sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #333f5b;
}

.form-control {
    width: 100%;
    padding: 15px;
    border: 1px solid #e1e5eb;
    border-radius: 8px;
    font-size: 16px;
    transition: all 0.3s ease;
    background-color: #f8f9fa;
}

.form-control:focus {
    border-color: #0c4a80;
    outline: none;
    box-shadow: 0 0 0 3px rgba(12, 74, 128, 0.1);
}

.form-control.is-invalid {
    border-color: #dc3545;
    background-color: rgba(220, 53, 69, 0.05);
}

select.form-control {
    height: 52px;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23333f5b' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 15px center;
    padding-right: 40px;
}

textarea.form-control {
    min-height: 150px;
    resize: vertical;
}

.invalid-feedback {
    color: #dc3545;
    font-size: 14px;
    margin-top: 6px;
    font-family: Avenir-Roman, sans-serif;
}

.submit-button {
    background-color: #0c4a80;
    color: white;
    border: none;
    padding: 15px 30px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 100%;
    margin-top: 10px;
    font-family: Avenir, sans-serif;
}

.submit-button:hover {
    background-color: #1a6bab;
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(12, 74, 128, 0.2);
}

.submit-button:active {
    transform: translateY(0);
    box-shadow: 0 2px 5px rgba(12, 74, 128, 0.2);
}

.submit-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
}

.form-group .grecaptcha-badge {
    margin: 20px 0;
}

@media (max-width: 992px) {
    .contact-container {
        padding: 40px;
    }
}

@media (max-width: 768px) {
    #contact {
        padding: 60px 20px;
    }
    
    .contact-container {
        flex-direction: column;
        gap: 40px;
        padding: 40px 30px;
    }

    .left-section {
        padding-right: 0;
        padding-bottom: 30px;
        border-bottom: 1px solid #e1e5eb;
    }

    .form-row {
        flex-direction: column;
        gap: 15px;
    }

    h2 {
        font-size: 32px;
    }
    
    .social-links {
        justify-content: center;
    }
}
