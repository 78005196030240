#apn-badge {
    height: 11em;
    margin-left: 1.5em;
    margin-top: 1em;
    transition: transform 0.3s ease;
    filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));
}

#apn-badge:hover {
    transform: scale(1.05);
}

.service-list { 
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
    padding-left: 1.5rem;
    color: #4b5563;
}

ul#services {
    list-style: none;
    padding-left: 0;
}

ul#services li {
    position: relative;
    padding-left: 1.75rem;
    margin-bottom: 0.75rem;
    line-height: 1.5;
}

ul#services li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0.5rem;
    width: 0.75rem;
    height: 0.75rem;
    background-color: #0c4a80ba;
    border-radius: 50%;
}

#splash-section {
    display: flex;
    align-items: center;
    margin: 2rem 0;
}

.row {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 2rem;
}

#calendly-section button, 
#services-section button {
    padding: 0.75rem 1.5rem;
    font-weight: 500;
    min-width: 200px;
}

#services-section button {
    background-color: transparent;
    border: 1px solid #0c4a80ba;
    color: #0c4a80ba;
}

#services-section button:hover {
    background-color: rgba(12, 74, 125, 0.05);
}

@media only screen and (max-width: 768px) {
    #splash-section {
        flex-direction: column;
        align-items: flex-start;
    }
    
    #apn-badge {
        height: 8em;
        margin-left: 0;
        margin-top: 1.5rem;
    }
    
    .row {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }
    
    #calendly-section, 
    #services-section {
        width: 100%;
    }
    
    #calendly-section button, 
    #services-section button {
        width: 100%;
    }
}
