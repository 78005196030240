#testimonials {
  padding: 80px 40px;
  background: transparent; /* Use the parent's background color */
  position: relative;
}

#testimonials .head {
  font-family: Avenir;
  font-size: 42px;
  font-weight: 900;
  line-height: 1.2;
  text-align: center;
  color: #333f5b;
  margin-bottom: 15px;
}

#testimonials .subtitle {
  font-family: Avenir-Roman;
  font-size: 20px;
  text-align: center;
  color: #505a72;
  margin-bottom: 60px;
}

.testimonials-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
}

.testimonial-card {
  background: white;
  border-radius: 12px;
  padding: 40px 30px;
  box-shadow: 0 8px 30px rgba(12, 74, 128, 0.08);
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid rgba(12, 74, 128, 0.05);
}

.testimonial-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(12, 74, 128, 0.12);
}

.quote-icon {
  font-family: Georgia, serif;
  font-size: 80px;
  line-height: 0;
  color: rgba(12, 74, 128, 0.1);
  position: absolute;
  top: 30px;
  left: 25px;
}

.quote-text {
  font-family: Avenir-Roman;
  font-size: 18px;
  line-height: 1.6;
  color: #505a72;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
}

.testimonial-author {
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(12, 74, 128, 0.1);
  padding-top: 20px;
}

.author-info h4 {
  font-family: Avenir;
  font-size: 18px;
  font-weight: 700;
  color: #333f5b;
  margin: 0 0 5px 0;
}

.author-info p {
  font-family: Avenir-Roman;
  font-size: 14px;
  color: #6d768a;
  margin: 0;
}

@media (max-width: 1024px) {
  .testimonials-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;
    padding: 0 20px;
  }
}

@media (max-width: 768px) {
  #testimonials {
    padding: 60px 20px;
  }

  #testimonials .head {
    font-size: 32px;
  }

  #testimonials .subtitle {
    font-size: 18px;
    margin-bottom: 40px;
  }

  .testimonials-container {
    grid-template-columns: 1fr;
    gap: 30px;
    padding: 0;
  }

  .quote-text {
    font-size: 16px;
  }
}
