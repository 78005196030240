#company-timeline {
  padding: 80px 40px;
  background: transparent; /* Use the parent's background color */
  position: relative;
}

#company-timeline .head {
  font-family: Avenir;
  font-size: 42px;
  font-weight: 900;
  line-height: 1.2;
  text-align: center;
  color: #333f5b;
  margin-bottom: 15px;
}

#company-timeline .subtitle {
  font-family: Avenir-Roman;
  font-size: 20px;
  text-align: center;
  color: #505a72;
  margin-bottom: 60px;
}

.timeline-container {
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
  padding: 40px 0;
}

.timeline-line {
  position: absolute;
  width: 4px;
  background-color: rgba(12, 74, 128, 0.1);
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -2px;
}

.timeline-item {
  padding: 10px 40px;
  position: relative;
  width: 50%;
  box-sizing: border-box;
  margin-bottom: 30px;
}

.timeline-item::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #0c4a80;
  border: 4px solid white;
  border-radius: 50%;
  top: 20px;
  z-index: 1;
  box-shadow: 0 0 0 4px rgba(12, 74, 128, 0.1);
}

.timeline-item.left {
  left: 0;
}

.timeline-item.right {
  left: 50%;
}

.timeline-item.left::after {
  right: -10px;
}

.timeline-item.right::after {
  left: -10px;
}

.timeline-content {
  padding: 25px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(12, 74, 128, 0.08);
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.timeline-content:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(12, 74, 128, 0.12);
}

.year-badge {
  display: inline-block;
  background-color: rgba(12, 74, 128, 0.1);
  color: #0c4a80;
  font-family: Avenir;
  font-weight: 700;
  font-size: 14px;
  padding: 5px 12px;
  border-radius: 20px;
  margin-bottom: 15px;
}

.timeline-content h3 {
  font-family: Avenir;
  font-size: 20px;
  font-weight: 700;
  color: #333f5b;
  margin: 0 0 10px 0;
}

.timeline-content p {
  font-family: Avenir-Roman;
  font-size: 16px;
  line-height: 1.6;
  color: #505a72;
  margin: 0;
}

@media (max-width: 768px) {
  #company-timeline {
    padding: 60px 20px;
  }

  #company-timeline .head {
    font-size: 32px;
  }

  #company-timeline .subtitle {
    font-size: 18px;
    margin-bottom: 40px;
  }

  .timeline-line {
    left: 40px;
  }

  .timeline-item {
    width: 100%;
    padding-left: 70px;
    padding-right: 20px;
  }

  .timeline-item.left,
  .timeline-item.right {
    left: 0;
  }

  .timeline-item.left::after,
  .timeline-item.right::after {
    left: 30px;
  }
}
