#team {
  padding: 80px 40px;
  background-color: white;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  margin: 0 40px;
}

#team .head {
  font-family: Avenir;
  font-size: 42px;
  font-weight: 900;
  line-height: 1.2;
  text-align: center;
  color: #333f5b;
  margin-bottom: 60px;
}

#members {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
}

.member {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 250px;
  transition: transform 0.3s ease;
}

.member:hover {
  transform: translateY(-5px);
}

.profile-image {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 20px;
  border: 4px solid white;
  box-shadow: 0 5px 15px rgba(12, 74, 128, 0.1);
}

.profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.member h3 {
  font-family: Avenir;
  font-size: 22px;
  font-weight: 700;
  color: #333f5b;
  margin: 0 0 5px 0;
}

.member h4 {
  font-family: Avenir-Roman;
  font-size: 16px;
  color: #0c4a80;
  margin: 0 0 15px 0;
}

.member a {
  text-decoration: none;
}

.member li {
  list-style: none;
  background-color: rgba(12, 74, 128, 0.05);
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 14px;
  color: #0c4a80;
  margin-bottom: 8px;
  transition: background-color 0.3s ease;
}

.member li:hover {
  background-color: rgba(12, 74, 128, 0.1);
}

@media (max-width: 768px) {
  #team {
    padding: 60px 20px;
  }

  #team .head {
    font-size: 32px;
    margin-bottom: 40px;
  }

  #members {
    gap: 30px;
  }

  .member {
    width: 100%;
    max-width: 250px;
  }
}
