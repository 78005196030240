/* General About Page Styles */
.main-content {
  min-height: 82.5vh;
  overflow: hidden;
}

#about {
  position: relative;
  background-color: #f8f9fa; /* Consistent light gray background for the entire page */
}

#about > div {
  position: relative;
  z-index: 2;
}

/* Subtle gradient overlay at the top */
#about::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 300px;
  background: linear-gradient(180deg, rgba(12, 74, 128, 0.03) 0%, rgba(248, 249, 250, 0) 100%);
  z-index: 1;
}

/* Section transitions with improved spacing */
#about > div:not(:last-child) {
  margin-bottom: 0; /* Sections have their own padding */
}

/* Smooth scrolling for better navigation experience */
html {
  scroll-behavior: smooth;
}

/* Call to Action Section */
#about-cta {
  padding: 100px 40px;
  background: linear-gradient(135deg, #0c4a80 0%, #1a6bab 100%);
  text-align: center;
  color: white;
  border-radius: 0;
  position: relative;
  overflow: hidden;
}

#about-cta::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQ0MCIgaGVpZ2h0PSI0MDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IHgxPSIwJSIgeTE9IjAlIiB4Mj0iMTAwJSIgeTI9IjEwMCUiIGlkPSJhIj48c3RvcCBzdG9wLWNvbG9yPSIjRkZGIiBzdG9wLW9wYWNpdHk9Ii4wNSIgb2Zmc2V0PSIwJSIvPjxzdG9wIHN0b3AtY29sb3I9IiNGRkYiIHN0b3Atb3BhY2l0eT0iMCIgb2Zmc2V0PSIxMDAlIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHBhdGggZD0iTTAgMzk5LjQyNmMxNDQuNjI4LTgyLjIzOSAyODkuMjU3LTEyMy4zNTggNDMzLjg4NS0xMjMuMzU4IDE0NC42MjkgMCAzNjEuNTcxIDgyLjIzOSA1MDYuMiA4Mi4yMzlDMTA4NC43MTQgMzU4LjMwNyAxMjYyLjkxNCAyMTYgMTQ0MCAyMTZ2MTgzLjQyNkgweiIgZmlsbD0idXJsKCNhKSIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+');
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 100% auto;
  opacity: 0.3;
}

.cta-container {
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

#about-cta h2 {
  font-family: Avenir;
  font-size: 42px;
  font-weight: 900;
  margin-bottom: 20px;
  color: white;
}

#about-cta p {
  font-family: Avenir-Roman;
  font-size: 20px;
  margin-bottom: 40px;
  color: rgba(255, 255, 255, 0.9);
}

.cta-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.primary-button {
  display: inline-block;
  padding: 15px 30px;
  background-color: white;
  color: #0c4a80;
  text-decoration: none;
  border-radius: 6px;
  font-family: Avenir;
  font-weight: 600;
  font-size: 18px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.primary-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
  text-decoration: none;
  color: #0c4a80;
}

.secondary-button {
  display: inline-block;
  padding: 15px 30px;
  background-color: transparent;
  color: white;
  text-decoration: none;
  border-radius: 6px;
  font-family: Avenir;
  font-weight: 600;
  font-size: 18px;
  transition: all 0.3s ease;
  border: 2px solid rgba(255, 255, 255, 0.3);
}

.secondary-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.5);
  transform: translateY(-3px);
  text-decoration: none;
  color: white;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  #about::before {
    height: 300px;
  }
  
  #about-cta {
    padding: 60px 20px;
  }
  
  #about-cta h2 {
    font-size: 32px;
  }
  
  #about-cta p {
    font-size: 18px;
    margin-bottom: 30px;
  }
  
  .cta-buttons {
    flex-direction: column;
    gap: 15px;
  }
  
  .primary-button, .secondary-button {
    width: 100%;
    text-align: center;
    padding: 12px 20px;
    font-size: 16px;
  }
}
