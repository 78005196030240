#offering {
  background-color: transparent;
  padding: 60px 0 80px;
  margin-bottom: 0;
  overflow: hidden;
}

#offering > .grid {
  margin-bottom: 60px;
  position: relative;
  z-index: 1;
}

#offering .section {
  margin-bottom: 60px;
}

#offering .services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  padding: 0 20px;
  max-width: 1200px;
  margin: 0 auto;
}

#offering .headline {
  font-family: Avenir;
  font-size: 32px;
  font-weight: 900;
  line-height: 1.2;
  text-align: center;
  color: #333f5b;
  margin-bottom: 20px;
  width: 100%;
}

#offering .feature {
  text-align: center;
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  border-radius: 12px;
  height: 100%;
  box-shadow: 0 8px 30px rgba(12, 74, 128, 0.08);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
}

#offering .feature:hover {
  transform: translateY(-8px);
  box-shadow: 0 15px 40px rgba(12, 74, 128, 0.12);
}

#offering #monthly-services .feature {
  border: 1px solid rgba(12, 74, 128, 0.1);
}

#offering .feature .image {
  margin: 0 auto 25px auto;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#offering .feature .image img {
  height: 100px;
  width: auto;
  object-fit: contain;
}

#offering .feature .headline {
  font-family: Avenir;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 15px;
  color: #333f5b;
}

#offering .feature .subtitle {
  font-family: Avenir-Roman;
  font-size: 16px;
  color: #0c4a80;
  margin-bottom: 20px;
}

#offering .feature .body {
  opacity: 0.85;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 15px;
}

#offering .feature .pricing {
  margin-top: auto;
  padding-top: 20px;
  width: 100%;
  border-top: 1px solid rgba(12, 74, 128, 0.1);
}

#offering .feature a.cta-button {
  display: inline-block;
  margin-top: 10px;
  padding: 12px 30px;
  color: #fff;
  background: #0c4a80;
  text-decoration: none;
  font-weight: 600;
  border-radius: 6px;
  transition: all 0.3s ease;
}

#offering .feature a.cta-button:hover {
  background: #1a6bab;
  transform: translateY(-3px);
  box-shadow: 0 8px 15px rgba(12, 74, 128, 0.2);
  text-decoration: none;
}

#offering #monthly-services .feature .body {
  font-size: 16px;
  margin: 8px 0;
  position: relative;
  padding-left: 25px;
  text-align: left;
}

#offering #monthly-services .feature .body::before {
  content: "✓";
  position: absolute;
  left: 0;
  color: #0c4a80;
  font-weight: bold;
}

#services, #monthly-services {
  padding: 40px 0 60px;
  background-color: transparent;
}

/* Popular badge styling */
.popular-badge {
  position: absolute;
  top: 0;
  right: 0;
  background: #0c4a80;
  color: white;
  padding: 8px 15px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 0 12px 0 12px;
}

/* Feature popular styling */
#offering .feature.popular {
  border: 2px solid #0c4a80;
  transform: scale(1.05);
  z-index: 2;
}

#offering .feature.popular:hover {
  transform: translateY(-8px) scale(1.05);
}

@media (max-width: 1024px) {
  #offering .services-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  #offering .feature.popular {
    transform: none;
  }
  
  #offering .feature.popular:hover {
    transform: translateY(-8px);
  }
}

@media (max-width: 768px) {
  #offering {
    padding: 40px 20px 60px;
  }
  
  #offering .headline {
    font-size: 28px;
  }
  
  #offering .services-grid {
    grid-template-columns: 1fr;
    gap: 30px;
    padding: 0;
  }
  
  #offering .feature {
    padding: 30px 25px;
    margin-bottom: 0;
  }
  
  #offering .section {
    margin-bottom: 40px;
  }
  
  #services, #monthly-services {
    padding: 20px 0 40px;
  }
}
