/* General Services Page Styles */
#services-page {
  min-height: 82.5vh;
  background-color: #f8f9fa;
  overflow: hidden;
}

#services-page h2 {
  font-family: Avenir;
  font-size: 36px;
  font-weight: 900;
  line-height: 1.2;
  text-align: center;
  color: #333f5b;
  margin-bottom: 20px;
  margin-top: -17px;
}

/* Hero Section */
#services-hero {
  padding: 100px 40px 80px;
  background: linear-gradient(135deg, #0c4a80 0%, #1a6bab 100%);
  text-align: center;
  color: white;
  position: relative;
  overflow: hidden;
}

#services-hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQ0MCIgaGVpZ2h0PSI0MDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IHgxPSIwJSIgeTE9IjAlIiB4Mj0iMTAwJSIgeTI9IjEwMCUiIGlkPSJhIj48c3RvcCBzdG9wLWNvbG9yPSIjRkZGIiBzdG9wLW9wYWNpdHk9Ii4wNSIgb2Zmc2V0PSIwJSIvPjxzdG9wIHN0b3AtY29sb3I9IiNGRkYiIHN0b3Atb3BhY2l0eT0iMCIgb2Zmc2V0PSIxMDAlIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHBhdGggZD0iTTAgMzk5LjQyNmMxNDQuNjI4LTgyLjIzOSAyODkuMjU3LTEyMy4zNTggNDMzLjg4NS0xMjMuMzU4IDE0NC42MjkgMCAzNjEuNTcxIDgyLjIzOSA1MDYuMiA4Mi4yMzlDMTA4NC43MTQgMzU4LjMwNyAxMjYyLjkxNCAyMTYgMTQ0MCAyMTZ2MTgzLjQyNkgweiIgZmlsbD0idXJsKCNhKSIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+');
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 100% auto;
  opacity: 0.3;
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

#services-hero h1 {
  font-family: Avenir;
  font-size: 48px;
  font-weight: 900;
  margin-bottom: 20px;
  color: white;
}

#services-hero p {
  font-family: Avenir-Roman;
  font-size: 22px;
  margin-bottom: 40px;
  color: rgba(255, 255, 255, 0.9);
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.hero-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.primary-button {
  display: inline-block;
  padding: 15px 30px;
  background-color: white;
  color: #0c4a80;
  text-decoration: none;
  border-radius: 6px;
  font-family: Avenir;
  font-weight: 600;
  font-size: 18px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.primary-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
  text-decoration: none;
  color: #0c4a80;
}

.secondary-button {
  display: inline-block;
  padding: 15px 30px;
  background-color: transparent;
  color: white;
  text-decoration: none;
  border-radius: 6px;
  font-family: Avenir;
  font-weight: 600;
  font-size: 18px;
  transition: all 0.3s ease;
  border: 2px solid rgba(255, 255, 255, 0.3);
}

.secondary-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.5);
  transform: translateY(-3px);
  text-decoration: none;
  color: white;
}

/* Process Section */
#services-process {
  padding: 80px 40px;
  background-color: white;
}

.process-content {
  max-width: 1200px;
  margin: 0 auto;
}

.process-description {
  text-align: center;
  font-family: Avenir-Roman;
  font-size: 20px;
  color: #505a72;
  margin-bottom: 60px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.process-steps {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.process-step {
  flex: 1;
  text-align: center;
  padding: 30px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 5px 20px rgba(12, 74, 128, 0.08);
  position: relative;
  transition: transform 0.3s ease;
}

.process-step:hover {
  transform: translateY(-5px);
}

.step-number {
  width: 50px;
  height: 50px;
  background: #0c4a80;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Avenir;
  font-size: 24px;
  font-weight: 700;
  margin: 0 auto 20px;
}

.process-step h3 {
  font-family: Avenir;
  font-size: 22px;
  font-weight: 700;
  color: #333f5b;
  margin-bottom: 15px;
}

.process-step p {
  font-family: Avenir-Roman;
  font-size: 16px;
  line-height: 1.6;
  color: #505a72;
}

/* Testimonials Section */
#services-testimonials {
  padding: 80px 40px;
  background-color: #f8f9fa;
}

#services-testimonials h2 {
  margin-bottom: 50px;
}

.testimonials-container {
  display: flex;
  justify-content: center;
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
}

.testimonial {
  flex: 1;
  max-width: 500px;
  background: white;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 5px 20px rgba(12, 74, 128, 0.08);
  position: relative;
}

.quote {
  font-family: Avenir-Roman;
  font-size: 18px;
  line-height: 1.6;
  color: #505a72;
  margin-bottom: 30px;
  position: relative;
  padding-left: 25px;
}

.quote::before {
  content: '"';
  position: absolute;
  left: 0;
  top: -10px;
  font-family: Georgia, serif;
  font-size: 60px;
  line-height: 1;
  color: rgba(12, 74, 128, 0.1);
}

.author {
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(12, 74, 128, 0.1);
  padding-top: 20px;
}

.author-info {
  display: flex;
  flex-direction: column;
}

.author-name {
  font-family: Avenir;
  font-size: 18px;
  font-weight: 700;
  color: #333f5b;
}

.author-title {
  font-family: Avenir-Roman;
  font-size: 14px;
  color: #6d768a;
}

/* FAQ Section */
#services-faq {
  padding: 80px 40px;
  background-color: white;
}

.faq-container {
  max-width: 800px;
  margin: 0 auto;
}

.faq-item {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(12, 74, 128, 0.1);
}

.faq-item:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.faq-item h3 {
  font-family: Avenir;
  font-size: 20px;
  font-weight: 700;
  color: #333f5b;
  margin-bottom: 15px;
}

.faq-item p {
  font-family: Avenir-Roman;
  font-size: 16px;
  line-height: 1.6;
  color: #505a72;
}

/* CTA Section */
#services-cta {
  padding: 80px 40px;
  background: linear-gradient(135deg, #0c4a80 0%, #1a6bab 100%);
  text-align: center;
  color: white;
  position: relative;
  overflow: hidden;
}

.cta-content {
  max-width: 700px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

#services-cta h2 {
  color: white;
  margin-bottom: 20px;
}

#services-cta p {
  font-family: Avenir-Roman;
  font-size: 20px;
  margin-bottom: 40px;
  color: rgba(255, 255, 255, 0.9);
}

.cta-button {
  display: inline-block;
  padding: 15px 30px;
  background-color: white;
  color: #0c4a80;
  text-decoration: none;
  border-radius: 6px;
  font-family: Avenir;
  font-weight: 600;
  font-size: 18px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.cta-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
  text-decoration: none;
  color: #0c4a80;
}

/* Responsive Styles */
@media (max-width: 768px) {
  #services-hero {
    padding: 60px 20px;
  }
  
  #services-hero h1 {
    font-size: 36px;
  }
  
  #services-hero p {
    font-size: 18px;
  }
  
  .hero-buttons {
    flex-direction: column;
    gap: 15px;
  }
  
  .primary-button, .secondary-button {
    width: 100%;
    text-align: center;
  }
  
  #services-process, #services-testimonials, #services-faq, #services-cta {
    padding: 60px 20px;
  }
  
  .process-steps {
    flex-direction: column;
  }
  
  .testimonials-container {
    flex-direction: column;
  }
  
  #services-page h2 {
    font-size: 28px;
  }
}

/* Add a popular badge to the most popular plan */
#offering .feature.popular {
  border: 2px solid #0c4a80;
  transform: scale(1.05);
  z-index: 2;
}

#offering .feature.popular:hover {
  transform: translateY(-4px) scale(1.05);
}

.popular-badge {
  position: absolute;
  top: 0;
  right: 0;
  background: #0c4a80;
  color: white;
  padding: 8px 15px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 0 12px 0 12px;
}
