.col-3_md-6 > div {
    /* Set a fixed height for the image container */
    height: 200px;
    /* Add any additional styles for the image container if needed */
  }
  
  .col-3_md-6 > div img {
    /* Set the image to cover the container */
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Add any additional styles for the image if needed */
  }

#case-studies {
    padding: 80px 40px;
    background: transparent; /* Use the parent's background color */
}

#case-studies .head {
    font-family: Avenir;
    font-size: 42px;
    font-weight: 900;
    line-height: 1.2;
    text-align: center;
    color: #333f5b;
    margin-bottom: 15px;
}

#case-studies .subtitle {
    font-family: Avenir-Roman;
    font-size: 20px;
    text-align: center;
    color: #505a72;
    margin-bottom: 60px;
}

.cases-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    max-width: 1200px;
    margin: 0 auto;
}

.case-study-card {
    background: white;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 12px rgba(0,0,0,0.08);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.case-study-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 24px rgba(0,0,0,0.12);
}

.logo-container {
    height: 160px;
    background: #f8f9fa;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-bottom: 1px solid rgba(12, 74, 128, 0.1);
}

.logo-container img {
    max-width: 80%;
    max-height: 80%;
    object-fit: contain;
}

.content {
    padding: 25px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.header h3 {
    font-family: Avenir;
    font-size: 20px;
    font-weight: 700;
    color: #333f5b;
    margin: 0;
}

.client-tag {
    background: rgba(12, 74, 128, 0.1);
    padding: 4px 12px;
    border-radius: 20px;
    font-size: 14px;
    color: #0c4a80;
    font-weight: 500;
}

.highlight {
    color: #0c4a80;
    font-family: Avenir;
    font-size: 24px;
    font-weight: 700;
    margin: 15px 0;
}

.description {
    color: #505a72;
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
}

.achievements {
    border-top: 1px solid rgba(12, 74, 128, 0.1);
    padding-top: 20px;
    margin-bottom: 25px;
}

.achievement-item {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 12px;
    font-size: 15px;
    color: #505a72;
}

.check-icon {
    color: #0c4a80;
    font-weight: bold;
}

.read-more {
    width: 100%;
    padding: 12px;
    background: transparent;
    border: 2px solid #0c4a80;
    border-radius: 6px;
    color: #0c4a80;
    font-family: Avenir;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.read-more:hover {
    background: #0c4a80;
    color: white;
}

@media (max-width: 1024px) {
    .cases-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 25px;
        padding: 0 20px;
    }
}

@media (max-width: 768px) {
    #case-studies {
        padding: 60px 20px;
    }

    #case-studies .head {
        font-size: 32px;
    }

    #case-studies .subtitle {
        font-size: 18px;
        margin-bottom: 40px;
    }

    .cases-grid {
        grid-template-columns: 1fr;
        gap: 30px;
        padding: 0;
    }

    .logo-container {
        height: 140px;
    }

    .content {
        padding: 20px;
    }
}
