#certifications {
  padding: 80px 40px;
  background-color: white;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  margin: 0 40px;
}

#certifications .head {
  font-family: Avenir;
  font-size: 42px;
  font-weight: 900;
  line-height: 1.2;
  text-align: center;
  color: #333f5b;
  margin-bottom: 15px;
}

#certifications .subtitle {
  font-family: Avenir-Roman;
  font-size: 20px;
  text-align: center;
  color: #505a72;
  margin-bottom: 60px;
}

.certifications-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 60px;
  max-width: 1200px;
  margin: 0 auto;
}

.certification-group {
  flex: 1;
  min-width: 300px;
  max-width: 500px;
}

.certification-group h3 {
  font-family: Avenir;
  font-size: 24px;
  font-weight: 700;
  color: #333f5b;
  margin-bottom: 30px;
  text-align: center;
  position: relative;
}

.certification-group h3::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background-color: rgba(12, 74, 128, 0.2);
}

.cert-items {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.cert-item {
  display: flex;
  align-items: center;
  background: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 15px rgba(12, 74, 128, 0.06);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid rgba(12, 74, 128, 0.05);
}

.cert-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(12, 74, 128, 0.1);
}

.cert-logo {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  flex-shrink: 0;
}

.cert-logo img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.aws-cert {
  background-color: #232F3E;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.aws-cert-icon {
  color: white;
  font-family: Avenir;
  font-weight: 900;
  font-size: 24px;
}

.cert-details h4 {
  font-family: Avenir;
  font-size: 18px;
  font-weight: 700;
  color: #333f5b;
  margin: 0 0 5px 0;
}

.cert-details p {
  font-family: Avenir-Roman;
  font-size: 14px;
  color: #505a72;
  margin: 0;
}

@media (max-width: 768px) {
  #certifications {
    padding: 60px 20px;
  }

  #certifications .head {
    font-size: 32px;
  }

  #certifications .subtitle {
    font-size: 18px;
    margin-bottom: 40px;
  }

  .certification-group {
    min-width: 100%;
  }

  .certification-group:not(:last-child) {
    margin-bottom: 40px;
  }

  .cert-logo {
    width: 60px;
    height: 60px;
    margin-right: 15px;
  }
}
